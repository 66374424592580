<template>
  <div>
    <div class="[ px-5 md:px-0 container mx-auto w-full overflow-hidden z-1 ] flex space-x-6">
      <div v-for="item in Array(30)" :key="item" class="loading h-16 w-46 flex-shrink-0" />
    </div>

    <div class="[ container mx-auto w-full ] flex justify-center">
      <section class="ProductListing__inner w-full">
        <div class="hidden md:flex px-5 lg:px-0 justify-between items-center ProductListing__filters">
          <h3 class="font-body capitalize text-primary-1-100 text-lg-1 font-medium">
            {{ $t('filterProducts') }}
          </h3>

          <div class="loading w-50 h-11" />
        </div>

        <div class="ProductListing__products">
          <LoadingProductsList class="px-6 lg:px-0" />
        </div>
      </section>
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps({
  items: {
    type: Number,
    required: false,
    default: 12,
  },
});

const { t: $t } = useI18n({
  useScope: 'local',
});
</script>

<style lang="postcss" scoped>
.ProductListing {
  &__inner {
    @apply md:mt-10;

    display: grid;
    grid-template-columns: 1fr;
    @screen lg {
      grid-template-columns: 1fr;
      column-gap: 30px;
    }
  }

  &__filters {
    @apply relative;
    height: 100%;
  }

  &__products {
    max-width: 100vw;
  }
}
</style>
<i18n>
{
  "en": {
    "notFoundTitle": "We couldn't find products that match your criteria",
    "notFoundTip": "Try using different filters to find other products",
    "products": "{totalCount} Products",
    "filterBy": "Filter by",
    "noResults": "No results",
    "filterProducts": "Filter products",
    "clearAll": "Clear all"
  },
  "ar": {
    "notFoundTitle": "لم يتم العثور على منتجات متطابقة",
    "notFoundTip": "من فضلك قم باختيار مرشحات مختلفة للعثور على منتجات اخري",
    "products": "{totalCount} منتج",
    "filterBy": "تسوق حسب",
    "noResults": "لا يوجد نتائج",
    "filterProducts": "فلترة المنتجات",
    "clearAll": "مسح الكل"
  }
}
</i18n>
